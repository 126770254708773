import '../assets/css/App.css';
import '../assets/css/interna.css';
import '../assets/css/movil.css';
import {React, useState, useEffect} from "react";
import Menu from '../components/menu/Menu';
import NavBar from '../components/layouts/partials/NavBar';
import NavBarPage from '../components/layouts/partials/NavBar/NavBarPage';
import Tabs from '../components/tab/Tabs';
import Dropdown from '../components/dropdown/Dropdown';
import Footer from '../components/footer/Footer';
import FooterC from '../components/footer/html/FooterC';
import Banner from '../components/banner/Banner';
import ModalFiltro from '../components/modal-filtro-libros/ModalFiltro';
import EndpointCore from "./Global";
import BuscadorMovil from '../components/BuscadorMovil/BuscadorMovil';

function RecomendadosDelDia() {
    const [itemsSelectionDay, setItemsSelectionDay] = useState([]);
    const [selectionDayLoading, setSelectionDayLoading] = useState(true);
    const limitPageSelectedDay = 3;
    const limitPageRecommends = 6;
    const endpointSelectedDay = EndpointCore.selectionDay + "&limitPage="+ limitPageSelectedDay +"&orderDir=DESC";
    const endpointRecommends = EndpointCore.recommends + "&limitPage="+ limitPageRecommends +"&orderDir=DESC";
    
    const protocol = window.location.protocol;
    const host = window.location.host;
    const protocolAndHost = protocol + "//" + host;
    const items_banner = [{ foto_movil: '/img/banner/bg_banner_home_libros.jpg', foto: '/img/banner/bg_banner_home_libros.jpg', texto: `Recomendados del Día` }];
    const ibanner = [{ foto_movil: '/img/banner/slider-trasparente.png', foto: '/img/banner/slider-trasparente.png', texto: `` }];
    const igenero = [
        { id: 1, foto: `${protocolAndHost}/img/card/bg_card_genders_1.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 2, foto: `${protocolAndHost}/img/card/bg_card_genders_2.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 3, foto: `${protocolAndHost}/img/card/bg_card_genders_3.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 4, foto: `${protocolAndHost}/img/card/bg_card_genders_3.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 5, foto: `${protocolAndHost}/img/card/bg_card_genders_5.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 6, foto: `${protocolAndHost}/img/card/bg_card_genders_6.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 7, foto: `${protocolAndHost}/img/card/bg_card_genders_7.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 8, foto: `${protocolAndHost}/img/card/bg_card_genders_8.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 9, foto: `${protocolAndHost}/img/card/bg_card_genders_9.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 10, foto: `${protocolAndHost}/img/card/bg_card_genders_10.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 11, foto: `${protocolAndHost}/img/card/bg_card_genders_1.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" },
        { id: 12, foto: `${protocolAndHost}/img/card/bg_card_genders_2.jpg`, texto: "Amamame con suavidad", capitulo: "capitulo", novela: "Novela", autor: "Grupo: Big Another Boyang", time: "Hace 3 horas", manga: "Manga", shoujo: "Shoujo", puntuacion: "8.9", vistas: "20.5k" }
    ];
    const itab = [
        { selected: 'true', href: 'comedia', texto: 'Comedia' },
        { selected: 'false', href: 'drama', texto: 'Drama' },
        { selected: 'false', href: 'fantasia', texto: 'Fantasía' },
        { selected: 'false', href: 'accion', texto: 'Acción' },
        { selected: 'false', href: 'drama2', texto: 'Drama' },
        { selected: 'false', href: 'fantasia2', texto: 'Fantasía' },
        { selected: 'false', href: 'accion2', texto: 'Acción' }]

    const icbo = [
        { cbo: 'Tipo', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { itemnpm : 'One shot' }] },
        { cbo: 'Etiquetas', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
        { cbo: 'Demografía', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
        { cbo: 'Por estado', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] }
    ];
    /*SELECTION DAY*/
    useEffect(() => {
        setSelectionDayLoading(true);
        const requestOptions = {
            method: "GET", redirect: "follow", headers: {}
        };
    
            fetch(endpointSelectedDay, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    let data_items = [];
                    result.items.forEach((i, index) => {
                        data_items.push({
                            "id": index + 1,
                            "foto": i.images[0].image.path,
                            "texto": i.bookTitle,
                            "capitulo": "21",//i.latestChapter.group.name,
                            "novela": i.bookType.name.value,
                            "autor": "Grupo: Big Another Boyang", //Configurar Api
                            "time": "Hace " + i.bookHourLatest + " horas",
                            "manga": "Seinen",//i.attributes.name.value,
                            "shoujo": "Novela",//i.bookType.name.value,
                            "puntuacion": i.bookRate,
                            "vistas": i.bookLabelViews
                        });
                        console.log(result)
                    });
                    setItemsSelectionDay(data_items);
                   
                })
                .catch((error) => {
                    console.log("error", error);
                })
                .finally(() => {
                    setSelectionDayLoading(false);
                });
    }, []);
    
    var ancho = window.innerWidth;
    var footerComponent = ancho <= 900 ? <FooterC /> : <Footer />;
    // CONFIG CARRUSEL RECOMENDADOS DEL DIA
    var configGenero = { rows: 8, draggable: true, slidesToShow: 2,  };
    if (ancho <= 900) {
        // CONFIG CARRUSEL RECOMENDADOS DEL DIA
        configGenero = { rows: 3, draggable: true, slidesToShow: 3,  };
    } else {
        // CONFIG CARRUSEL RECOMENDADOS DEL DIA
        configGenero = { rows: 8, draggable: true, slidesToShow: 2,  };
    }
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
  
    return (
        <div>
        <div className="interna  recomendados-del-dia">
        <div className='buscador'>
         <a href="/" >
          <img src='./img/svg/icon_arrow_5F36DA.svg' style={{ width: 20, transform: 'rotate(90deg)', marginLeft: '10px'}}></img> 
          </a>
         <BuscadorMovil />
         </div>
        <div className="position_header position">
          
          <NavBar />
          <NavBarPage />
        </div>
      
      <div className= 'container' >
        <div className="col01 w-100 publicidad oculta_movile">
          <div className="ui_brand titulo_seccion">
            <a href="#" className="ui_brand__a">
              Recomendados
              <br /> del día
            </a>
          </div>
          <img src={`${protocolAndHost}/img/banner/quienes-somos.png`} alt="" />
        </div>
                <div className='container '>
                    <div className="psidebar">
                        <div className='box-row subtitulo margin-button-20'>
                            <span>Filtrar Resultados </span>
                        </div>
                        <div className='box-row dropdown-blue'>{<Dropdown icbo={icbo} ></Dropdown>}</div>
                        <div className='box-row group-button'>
                            <button className="bg-rosado">Filtrar</button>
                            <button className="bg-transparente-azul">Limpiar Filtro</button>
                        </div>
                    </div>
                   <div className='pcontent'>
                    <div> 
                    <div className=' carrusel  '>            
        <Tabs itab={itab} key_position={'tabkey002'} titulo={'Recomendados del día'} icarrusel={itemsSelectionDay} config={configGenero} showBanner={true}/> 
                   </div> 
                   </div> 
                        <div className='box-row group-button limit'>
                            <button className="bg-rosado left">Anterior</button>
                            <button className="bg-rosado right">Siguiente</button>
                          
                        </div>
                    </div>
                </div>
            </div>
               
              
               {showModal && ( <ModalFiltro show={showModal} onHide={handleCloseModal} icbo={icbo} /> )}
              {footerComponent}
              </div>
              </div>
           
        
    );
}

export default RecomendadosDelDia;