import React, { useState } from 'react';
import './style.css'; 
import EndpointCore from '../../pages/Global';

function ReportarDropdown({ notification_type, user_id, entity_id, isComment, comment_id, reply_id, closeDropdown }) {
    // Obtén el token de autorización (por ejemplo, desde localStorage)
    const token = localStorage.getItem('token'); // Ajusta la clave según tu implementación
    
    const defaultOptions = [
        { ibutton: "Contenido inapropiado" },
        { ibutton: "Derecho de autor no autorizado" },
        { ibutton: "Error de contenido" },
        { ibutton: "Obra Plagiada" },
    ];

    const commentOptions = [
        { ibutton: "Acoso dirigido" },
        { ibutton: "Spam" },
        { ibutton: "Perfil inapropiado" },
        { ibutton: "Contenido amenazante" },
        { ibutton: "Suplantación de identidad" },
        { ibutton: "Información privada" },
    ];

    const options = isComment ? commentOptions : defaultOptions;

    const [tituloDropdown, setTituloDropdown] = useState('Tipo de denuncia');
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [reporteContenido, setReporteContenido] = useState('');

    const handleOptionSelect = (titulo) => {
        setTituloDropdown(titulo);
        setOpcionSeleccionada(titulo);
        setShowPopup(true); // Muestra el popup al seleccionar una opción
        console.log('Opción seleccionada:', titulo);
        console.log('Mostrando popup:', showPopup);
    };

    const handlePopupClose = () => {
        console.log('Cerrando popup');
        setShowPopup(false); // Oculta el popup
    };

    const handleContenidoChange = (event) => {
        console.log('Cambiando contenido del reporte:', event.target.value);
        setReporteContenido(event.target.value); // Actualiza el contenido del reporte
    };

    const handleSubmit = async () => {
        if (!opcionSeleccionada || !reporteContenido.trim()) {
            console.error("Debe seleccionar una opción y escribir el contenido del reporte.");
            return;
        }
    
        // Construir el cuerpo de la solicitud
        const payload = {
            report_type: opcionSeleccionada,
            content: reporteContenido,
            notification_type,
            user_id,
            entity_id,
        };

        // Agregar comment_id y reply_id si están presentes
        if (comment_id) {
            payload.comment_id = comment_id;
            console.log('EL COMMENTID REPORTES REPLY',comment_id)
        }
        if (reply_id) {
            payload.reply_id = reply_id;
        }

        try {
            const response = await fetch(EndpointCore.createReport, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Incluye el token en los encabezados
                },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Reporte enviado con éxito:', data);
                setShowPopup(false);
                closeDropdown();
            } else {
                console.error('Error al enviar el reporte:', response.statusText);
            }
        } catch (error) {
            console.error('Error al enviar el reporte:', error);
        }
    };

    return (
        <div className='custom-dropdown-button'>
            <div className='custom-dropdown-container'>
                <div className="custom-dropdown-title">{tituloDropdown}</div>
                {options.map((option, index) => (
                    <div
                        key={index}
                        className={`custom-dropdown-item ${opcionSeleccionada === option.ibutton ? 'selected' : ''}`}
                        onClick={() => handleOptionSelect(option.ibutton)} 
                    >
                        <div className="custom-dropdown-circle">
                            <div className={`custom-dropdown-check ${opcionSeleccionada === option.ibutton ? 'selected' : ''}`} />
                        </div>
                        <span>{option.ibutton}</span>
                    </div>
                ))}
            </div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h3>Agregar contenido del reporte</h3>
                        <textarea 
                            value={reporteContenido} 
                            onChange={handleContenidoChange} 
                            placeholder="Escribe aquí el contenido del reporte..."
                        />
                        <div className='dropdown-button-container'>
                            <button onClick={handleSubmit} className='btn-rosado'>Enviar</button>
                            <button onClick={handlePopupClose} className='cancel-edit'>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default ReportarDropdown