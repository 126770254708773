import React, { useState, useEffect } from "react";
import TabsMain from "../components/tabs-main";
import Footer from "../components/footer/Footer";
import FooterC from "../components/footer/html/FooterC";
import NavBar from "../components/layouts/partials/NavBar";
import CardLibraryNoLogin from "../components/cardLibraryNoLogin";
import ModalLibraryNoLogin from "../components/modalLibraryNoLogin";
import NavBarPage from "../components/layouts/partials/NavBar/NavBarPage";
import Menu from "../components/menu/Menu";
import "../assets/css/App.css";
import "../assets/css/interna.css";
import "../assets/css/movil.css";
import "../assets/css/base.css";
import ModalFiltro from '../components/modal-filtro-libros/ModalFiltro';
import EndpointCore from './Global';
import useAuth from "../hook/useAuth";

const isLoggedIn = localStorage.getItem('token');

export default function LibraryNoLogin() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [books, setBooks] = useState([]);
  const [isSelection, setIsSelection] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [isHistorial, setIsHistorial] = useState(false);
  const auth = useAuth();
  const userId = auth?.auth?.id;
  const [tab, setTab] = useState({ id: 1, name: "Seguidos" });
  const [orderList, setOrderList] = useState({ id: 1, name: "Recomendados" });
  
  const icbo = [
    { cbo: 'Tipo', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { itemnpm : 'One shot' }] },
    { cbo: 'Etiquetas', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
    { cbo: 'Demografía', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
    { cbo: 'Por estado', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] }
];

  const optionTabs = [
    { id: 1, name: "Seguidos" },
    { id: 2, name: "Favoritos" },
    { id: 3, name: "Descartados" },
    { id: 4, name: "Historial" },
  ];

  const fetchBooksByStatus = async (status, userId) => {
    try {
      const response = await fetch(EndpointCore.getBooksByStatus(status, userId), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();
      setBooks(data.data);
    } catch (error) {
      console.error('Error fetching books by status:', error);
    }
  };

  useEffect(() => {
    // Fetch the books based on the selected tab and reset selected books
    if (userId) {
      fetchBooksByStatus(tab.name, userId);
      setSelectedBooks([]); // Reset selected books when the tab changes
    }
  }, [tab, userId]);

  useEffect(() => {
    if (!isLoggedIn) setIsOpen(true);
  }, [isLoggedIn]);

  useEffect(() => {
    // Actualiza isHistorial basado en el tab seleccionado
    setIsHistorial(tab.name === "Historial");
  }, [tab]);

  useEffect(() => {
    const handleScroll = () => {
      document.body.style.overflowY = isOpen ? 'hidden' : 'auto';
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  const handleOpenModalFiltro = async () => {
    try {
      setShowModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModalFiltro = () => setShowModal(false);
  const handleCloseModal = () => setIsOpen(false);

  const toggleSelection = () => setIsSelection(!isSelection);

  const handleCheckboxChange = (e, bookId) => {
    if (e.target.checked) {
      setSelectedBooks(prevSelected => [...prevSelected, bookId]);
    } else {
      setSelectedBooks(prevSelected => prevSelected.filter(id => id !== bookId));
    }
  };

  const getNewStatus = (currentTab) => {
    switch (currentTab) {
      case "Seguidos":
        return "Favoritos";
      case "Favoritos":
        return "Seguidos";
      case "Descartados":
        return "Favoritos"
      default:
        return "Favoritos ";
    }
  };
  const getNewStatus2 = (currentTab) => {
    switch (currentTab) {
      case "Seguidos":
        return "Descartados";
      case "Favoritos":
        return "Descartados";
      case "Descartados":
        return "Seguidos";
      default:
        return "Descartados";
    }
  };


  const handleChangeStatus = async (newStatus) => {
    try {
      if (selectedBooks.length === 0) {
        alert("No hay libros seleccionados.");
        return;
      }

      const response = await fetch(EndpointCore.changeLibraryStatus, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':  `Bearer${isLoggedIn}`,
        },
        body: JSON.stringify({
          userId,
          status: newStatus,
          bookIds: selectedBooks,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Status changed successfully:', data);
        fetchBooksByStatus(tab.name, userId); 
        setSelectedBooks([]); 
      } else {
        console.error('Error changing status:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  var ancho = window.innerWidth;
  var footerComponent = ancho <= 900 ? <FooterC /> : <Footer />;

  return (
    <>
      <div className='interna biblioteca_no_login'>
        <div className="interna libro">
          <div className="position_header position">
            <div className="oculta_movile">
              <NavBar />
              <NavBarPage />
            </div>
            <div className="oculta_desktop">
              <div className="d-flex align-items-center px-2">
                <a href="/" className="brand-logo">Dmangas</a>
                <Menu />
              </div>
              <div className="hide_desk titulo-seccion">Tu Biblioteca</div>
            </div>
            <div className="ui_container ui_container--946">
              <div className="seccion_interna comunidad_detalle_libro">
                <div className="oculta_movile" style={{ marginBottom: 300 }}>
                  <div className="ui_brand titulo_seccion">
                    <a href="#" className="ui_brand__a text-white">Biblioteca<br /></a>
                  </div>
                  <img className="w-100" src={`${window.location.protocol}//${window.location.host}/img/interna/comunidad/ibanner/banner.png`} alt="" />
                </div>
                <div className="oculta_desktop" style={{ marginBottom: 100 }}>
                  <div className="ui_brand titulo_seccion">
                    <a href="#" className="ui_brand__a text-white">Tu Biblioteca<br /></a>
                  </div>
                  <img className="w-100" src={`${window.location.protocol}//${window.location.host}/img/interna/comunidad/ibanner/banner.png`} alt="" />
                </div>
                <div className="col01 w-100 publicidad oculta_movile"></div>
                <div className="contenido">
                  <div className="box body">
                    <div className="box grid-tab">
                      <div className="tab-biblioteca">
                        <TabsMain optionTabs={optionTabs} setTab={setTab} tab={tab} />
                        <div className="box botones">
                          {isSelection ? (
                            <>
                              <div className="biblioteca-icons">
                                <img src="/img/svg/desSeleccionar.svg" alt="seleccionados" onClick={toggleSelection} />
                                <p>{selectedBooks.length} seleccionados</p>
                              </div>
                              <div className="biblioteca-icons"
                              onClick={() => handleChangeStatus(getNewStatus(tab.name))}
                              >
                                <img
                                  src="/img/svg/moverFavoritos.svg"
                                  alt="mover"
                                />
                                <p>{tab.name === "Favoritos" ? "Mover a Seguidos" : "Mover a Favoritos"}</p>
                              </div>
                              <div className="biblioteca-icons"
                               onClick={() => handleChangeStatus(getNewStatus2(tab.name))}
                              >
                                <img
                                  src="/img/svg/descartados.svg"
                                  alt="mover"
                                />
                                <p>{tab.name === "Descartados" ? "Mover a Seguidos" : "Mover a Descartados"}</p>
                              </div>
                            </>
                          ) : (
                             !isHistorial && (
                            <>
                              <button className="btn btn-rosado prev" type="button" onClick={handleOpenModalFiltro}>Filtrar</button>
                              <button className="btn btn-rosado" type="button" onClick={toggleSelection}>Seleccionar</button>
                            </>
                            )
                          )}
                        </div>
                      </div>
                      <div id="tab1" className="tabcontent" style={{ display: "block" }}>
                      <div className="box">
                       <ul
                         className={`catalogo comunidad ${isHistorial ? 'historial-flex' : 'default-flex'}`}
                       >
                       {books?.length > 0 ? (
                        books.map((book) => (
                        <CardLibraryNoLogin
                        key={book.id}
                        book={book}
                        isSelection={isSelection}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedBooks={selectedBooks}
                        isHistorial={isHistorial}
                        />
                       ))
                        ) : (
                          <p>No hay libros para mostrar.</p>
                        )}
                    </ul>
                   </div>
                  </div>
                      <div className="box botones pagination">
                      <button className="btn btn-rosado prev" type="button" >Anterior</button>
                      <button className="btn btn-rosado next" type="button">Siguiente</button>
                      </div>
                    </div>
                    <ModalFiltro show={showModal} onClose={handleCloseModalFiltro} icbo={icbo}/>
                    <ModalLibraryNoLogin isOpen={isOpen} onClose={handleCloseModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {footerComponent}
    </>
  );
}