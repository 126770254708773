import React from 'react';

const BannerAdssense = () => {
    const bannerStyle = {
        fontSize: '4vw',
        border: '1px solid black',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        marginTop: '5px',
        textAlign: 'center',
    
    };
  
    return (
        <div style={bannerStyle}>BannerAdssense</div>
    );
}

export default BannerAdssense;
