const customStickers = {
  nathanVanHove: [
    {
      id: 'criss_free',
      name: 'Criss_Free',
      stickers: [
       
        {
          id: 'Nathan_Criss_1',
          name: 'Nathan Criss  1',
          keywords: ['Cry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/1.png' }],
        },
        {
          id: 'Nathan_Criss_2',
          name: 'Nathan Criss 2',
          keywords: ['Loading', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/2.png' }],
        },
        {
          id: 'Nathan_Criss_3',
          name: 'Nathan Criss 3',
          keywords: ['Hello', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/3.png' }],
        },
        {
          id: 'Nathan_Criss_4',
          name: 'Nathan Criss 4',
          keywords: ['Emotion', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/4.png' }],
        },
        {
          id: 'Nathan_Criss_5',
          name: 'Nathan Criss 5',
          keywords: ['Lights', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/5.png' }],
        },
        {
          id: 'Nathan_Criss_6',
          name: 'Nathan Criss 6',
          keywords: ['Ok', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/6.5.png' }],
        },
        {
          id: 'Nathan_Criss_7',
          name: 'Nathan Criss 7',
          keywords: ['eating', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/7.png' }],
        },
        {
          id: 'Nathan_Criss_8',
          name: 'Nathan Criss 8',
          keywords: ['hungry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/8.png' }],
        },
        {
          id: 'Nathan_Criss_9',
          name: 'Nathan Criss 9',
          keywords: ['like', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/9.png' }],
        },
        {
          id: 'Nathan_Criss_10',
          name: 'Nathan Criss 10',
          keywords: ['amaizing', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/10.png' }],
        },
        {
          id: 'Nathan_Criss_11',
          name: 'Nathan Criss 11',
          keywords: ['custom', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/11.png' }],
        },
        {
          id: 'Nathan_Criss_12',
          name: 'Nathan Criss 12',
          keywords: ['sleep', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Criss_Model/12.png' }],
        },
      ],
    },
    {
      id: 'hato_kunstler',
      name: 'Hato_Kunstler',
      stickers: [
        {
          id: 'Nathan_Hato_1',
          name: 'Nathan Hato 1',
          keywords: ['question', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/1.png' }],
        },
        {
          id: 'Nathan_Hato_2',
          name: 'Nathan Hato 2',
          keywords: ['drink', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/2.png' }],
        },
        {
          id: 'Nathan_Hato_3',
          name: 'Nathan Hato 3',
          keywords: ['cry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/3.png' }],
        },
        {
          id: 'Nathan_Hato_4',
          name: 'Nathan Hato 4',
          keywords: ['hungry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/4.png' }],
        },
        {
          id: 'Nathan_Hato_5',
          name: 'Nathan Hato 5',
          keywords: ['happy', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/5.png' }],
        },
        {
          id: 'Nathan_Hato_6',
          name: 'Nathan Hato 6',
          keywords: ['afraid', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/6.png' }],
        },
        {
          id: 'Nathan_Hato_7',
          name: 'Nathan Hato 7',
          keywords: ['comprension', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/7.png' }],
        },
        {
          id: 'Nathan_Hato_8',
          name: 'Nathan Hato 8',
          keywords: ['dub', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/8.png' }],
        },
        {
          id: 'Nathan_Hato_9',
          name: 'Nathan Hato 9',
          keywords: ['sleep', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/9.png' }],
        },
        {
          id: 'Nathan_Hato_10',
          name: 'Nathan Hato 10',
          keywords: ['laught', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/10.png' }],
        },
        {
          id: 'Nathan_Hato_11',
          name: 'Nathan Hato 11',
          keywords: ['love', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/11.png' }],
        },
        {
          id: 'Nathan_Hato_12',
          name: 'Nathan Hato 12',
          keywords: ['devil', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Nathan_Van_Hove/Hato_Model/12.png' }],
        },
      ],

    },
  ],
  akariVanHove: [
    {
      id: 'criss_free',
      name: 'Criss_Free',
      stickers: [
        {
          id: 'Akari_Criss_1',
          name: 'Akari Criss 1',
          keywords: ['Crying', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/1_(1).png' }],
        },
        {
          id: 'Akari_Criss_2',
          name: 'Akari Criss 2',
          keywords: ['loading', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/2_(1).png' }],
        },
        {
          id: 'Akari_Criss_3',
          name: 'Akari Criss 3',
          keywords: ['hello', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/3_(1).png' }],
        },
        {
          id: 'Akari_Criss_4',
          name: 'Akari Criss 4',
          keywords: ['blood cry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/4_(1).png' }],
        },
        {
          id: 'Akari_Criss_5',
          name: 'Akari Criss 5',
          keywords: ['lights', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/5_(1).png' }],
        },
        {
          id: 'Akari_Criss_6',
          name: 'Akari Criss 6',
          keywords: ['good', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/6_(1).png' }],
        },
        {
          id: 'Akari_Criss_7',
          name: 'Akari Criss 7',
          keywords: ['eat', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/7_(1).png' }],
        },
        {
          id: 'Akari_Criss_8',
          name: 'Akari Criss 8',
          keywords: ['surprise', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/8_(1).png' }],
        },
        {
          id: 'Akari_Criss_9',
          name: 'Akari Criss 9',
          keywords: ['emotion', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/9_(1).png' }],
        },
        {
          id: 'Akari_Criss_10',
          name: 'Akari Criss 10',
          keywords: ['baba', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/10_(1).png' }],
        },
        {
          id: 'Akari_Criss_11',
          name: 'Akari Criss 11',
          keywords: ['sleep', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/11_(1).png' }],
        },
        {
          id: 'Akari_Criss_12',
          name: 'Akari Criss 12',
          keywords: ['happiness', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Criss_Model/12_(1).png' }],
        },      
      ],
    },
    {
      id: 'hato_kunstler',
      name: 'Hato_Kunstler',
      stickers: [
        {
          id: 'Akari_Hato_1',
          name: 'Akari Hato 1',
          keywords: ['pizza', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/1.png' }],
        },
        {
          id: 'Akari_Hato_2',
          name: 'Akari Hato 2',
          keywords: ['happy', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/2.png' }],
        }, 
        {
          id: 'Akari_Hato_3',
          name: 'Akari Hato 3',
          keywords: ['cold', 'afraid', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/3.png' }],
        },
        {
          id: 'Akari_Hato_4',
          name: 'Akari Hato 4',
          keywords: ['shout', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/4.png' }],
        },
        {
          id: 'Akari_Hato_5',
          name: 'Akari Hato 5',
          keywords: ['guiño', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/5.png' }],
        },
        {
          id: 'Akari_Hato_6',
          name: 'Akari Hato 6',
          keywords: ['litle cry', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/6.png' }],
        },
        {
          id: 'Akari_Hato_7',
          name: 'Akari Hato 7',
          keywords: ['sonrojo', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/7.png' }],
        },
        {
          id: 'Akari_Hato_8',
          name: 'Akari Hato 8',
          keywords: ['tired', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/8.png' }],
        },
        {
          id: 'Akari_Hato_9',
          name: 'Akari Hato 9',
          keywords: ['esquimal', 'sleep', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/9.png' }],
        },
        {
          id: 'Akari_Hato_10',
          name: 'Akari Hato 10',
          keywords: ['question', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/10.png' }],
        },
        {
          id: 'Akari_Hato_11',
          name: 'Akari Hato 11',
          keywords: ['toungh', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/11.png' }],
        },
        {
          id: 'Akari_Hato_12',
          name: 'Akari Hato 12',
          keywords: ['hapiness', 'sticker'],
          skins: [{ src: '/img/custom_stickers/Akari_Van_Hove/Hato_Model/12.png' }],
        },
      ],
    },
  ],
};

export default customStickers;