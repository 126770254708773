export default function Promo()
{
    return(
        <div className='html'>
                <ul>
                   <li className='first-img'>
                     <img loading="lazy" src="./img/login/cover/bg_login.jpg" alt="" />
                   </li>
                    <li className='white-cuadrante'>
                        
                    </li>
                </ul>
            </div>
    );     
};