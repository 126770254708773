import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";

const DropdownAgrupacion = ({ options, onSelect, label }) => {
  const [selectedId, setSelectedId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Referencia al contenedor del dropdown

  // Función para manejar el clic fuera del dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Cierra el dropdown si el clic fue fuera de él
    }
  };

  // Añadir y limpiar el event listener en el useEffect
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedId(option.id);
    onSelect(option.id); // Pasar el id al onSelect
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState); // Alterna el estado de apertura del dropdown
  };

  const handleChevronClick = (event) => {
    event.stopPropagation(); // Evita que el clic en la flecha cierre el dropdown si ya está abierto
    toggleDropdown();
  };

  return (
    <div
      className="custom-dropdown-custom w-100"
      ref={dropdownRef} // Asigna la referencia al contenedor del dropdown
    >
      <div className="d-flex align-items-center" onClick={toggleDropdown}>
        {/* Mostrar el label solo si no hay ninguna opción seleccionada */}
        {!selectedId && <span>{label}</span>}

        {/* Mostrar la opción seleccionada cuando existe */}
        {selectedId && (
          <div className="selected-option d-flex align-items-center" style={{ width: "100%" }}>
            {options.find((option) => option.id === selectedId).images?.[0]?.base64 && (
              <img
                src={`data:image/png;base64,${options.find((option) => option.id === selectedId).images[0].base64}`}
                alt={`Group ${selectedId}`}
                className="group-image"
              />
            )}
            <span style={{ textAlign: "center" }}>
              {options.find((option) => option.id === selectedId).name}
            </span>
          </div>
        )}

        <div className="dropdown-icon" style={{ marginRight: "30px" }} onClick={handleChevronClick}>
          {!isOpen ? (
            <BiChevronDown color="#5F36DA" />
          ) : (
            <BiChevronUp color="#FF0055" />
          )}
        </div>
      </div>

      {isOpen && (
        <ul className="dropdown-menu-custom">
          {options.map((option) => (
            <li
              key={option.id}
              onClick={() => handleOptionSelect(option)}
              className="d-flex align-items-center"
            >
              {option.images?.[0]?.base64 && (
                <div className="group-image">
                  <img
                    src={`data:image/png;base64,${option.images[0].base64}`}
                    alt={`Group ${option.id}`}
                    className="dropdown-image"
                  />
                </div>
              )}
              <span>{option.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownAgrupacion;
