const customEmojis = [
  {
    id: 'custom',
    name: 'Custom Emojis',
    emojis: [
      {
        id: 'point_up',
        name: 'Point Up',
        keywords: ['point', 'up'],
        skins: [{ src: '/img/custom_emojis/Sandia.gif' }],
        native: [{ src: '/img/custom_emojis/Sandia.gif' }],

      },
      {
        id: 'afraid',
        name: 'Afraid',
        keywords: ['afraid', 'dub'],
        skins: [{ src: '/img/custom_emojis/Afraid.gif' }],
      },
      {
        id: 'analyze',
        name: 'Analyze',
        keywords: ['analyze', 'thinking'],
        skins: [{ src: '/img/custom_emojis/Analyze.gif' }],
      },
      {
        id: 'cry',
        name: 'Cry',
        keywords: ['cry', 'sad'],
        skins: [{ src: '/img/custom_emojis/Cry.gif' }],
      },
      {
        id: 'die',
        name: 'Die',
        keywords: ['die', 'hungry'],
        skins: [{ src: '/img/custom_emojis/Die.gif' }],
      },
      {
        id: 'dog',
        name: 'Dog',
        keywords: ['dogface'],
        skins: [{ src: '/img/custom_emojis/Dog.gif' }],
      },
      {
        id: 'drink',
        name: 'Drink',
        keywords: ['drink', 'energy'],
        skins: [{ src: '/img/custom_emojis/Drink.gif' }],
      },
      {
        id: 'good',
        name: 'Good',
        keywords: ['good', 'ok', 'yes'],
        skins: [{ src: '/img/custom_emojis/Good.gif' }],
      },
      {
        id: 'knife',
        name: 'Knife',
        keywords: ['cut', 'knife', 'danger'],
        skins: [{ src: '/img/custom_emojis/Knife.gif' }],
      },
      {
        id: 'laugh',
        name: 'Laugh',
        keywords: ['laugh', 'happy'],
        skins: [{ src: '/img/custom_emojis/Laugh.gif' }],
      },
      {
        id: 'perfect',
        name: 'Perfect',
        keywords: ['perfect', 'ok', 'right'],
        skins: [{ src: '/img/custom_emojis/Perfect.gif' }],
      },
      {
        id: 'sandia',
        name: 'Sandia',
        keywords: ['sandia', 'fruit'],
        skins: [{ src: '/img/custom_emojis/Sandia.gif' }],
      },
    ],
  },
];

export default customEmojis;