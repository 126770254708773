import './style.css';
import React from 'react'

function Rueda() {       
    return(
        <div className="rueda">
            <div id="ruedaByCss" className="h-100 d-flex justify-content-center align-items-center">
                <div id="ruedaBackgroundByCss"></div>
            </div>
           {/*<img className='rueda-foto' src="/img/svg/elipse.svg" alt="" />*/}
          
        </div>   
      );
}
export default Rueda;
