import './footerB.css';
import React from 'react'

function FooterB () {
   function cerrarRosado() {
      let rosado = document.getElementById("modal-rosado");
       rosado.classList.add("hide_movil");
   }

   return(      
         <div id='modal-rosado' className="footer_movil_rosado hide_desk">
            <div className="play-rosado">
               <img className='img01' src="/img/svg/icon_play.svg" alt="" />
               <div className="texto">
                <div className='titulo'>Continuar leyendo:</div> 
                <div className='subtitulo'>Wan Gu Shen Wang | Cap. 02 </div>                
               </div>
               <img onClick={cerrarRosado} className='img02' src="/img/svg/icon_close_movil.svg" alt="" />
            </div>
         </div>         
   );      
}
export default FooterB;