const Global = {
    API_URL: process.env.REACT_APP_API_URL_CORE,
    navbar : process.env.REACT_APP_API_URL_CORE + "/pages?viewOnNavBar=true",
    bootom : process.env.REACT_APP_API_URL_CORE + "/pages?viewOnBootom=true&associateByGroup=true",
    homeSlider : process.env.REACT_APP_API_URL_CORE + "/banners?page=home&bannerType=SLIDER&withImages=true",
    lastUpdates : process.env.REACT_APP_API_URL_CORE + "/books?withImages=true&showLatestChapter=true",
    selectionDay : process.env.REACT_APP_API_URL_CORE + "/books?withImages=true&showLatestTopDay=true",
    recommends : process.env.REACT_APP_API_URL_CORE + "/books?withImages=true&showRecommended=true",
    searchBookByTitle: (tituloOriginal) => `${process.env.REACT_APP_API_URL_CORE}/books?withImages=true&limitPage=9&orderDir=DESC&filterUrl=${encodeURIComponent(tituloOriginal)}`,
    categoriesOnHomeByGenderSection : process.env.REACT_APP_API_URL_CORE + "/attributes?limitPage=7&attributeType=categories",
    filterByCategory : process.env.REACT_APP_API_URL_CORE + "/books?limitPage=6&withImages=true&orderDir=DESC&filterByCategory=true",
    base_url:process.env.BASE_URL,
    pageShow : process.env.REACT_APP_API_URL_CORE + "/pages/",
    settings : process.env.REACT_APP_API_URL_CORE + "/settings?",
    NODE_API_URL: process.env.REACT_APP_API_URL_AUTH,
    profile: process.env.REACT_APP_API_URL_AUTH + "/profile",
    memberInfo: process.env.REACT_APP_API_URL_AUTH + "/member-info",
    login: process.env.REACT_APP_API_URL_AUTH + "/login",
    googleLogin: process.env.REACT_APP_API_URL_AUTH + "/login/googleauth",
    forgetPassword: process.env.REACT_APP_API_URL_AUTH + "/forget-password",
    confirmUser: process.env.REACT_APP_API_URL_AUTH + "/confirm",
    createGroup: process.env.REACT_APP_NODE_API_CORE_URL + "/create",
    uploadImages: process.env.REACT_APP_NODE_API_CORE_URL +"/upload-images",
    getGroupWithImages: process.env.REACT_APP_NODE_API_CORE_URL + "/group-with-images",
    updateGroup: process.env.REACT_APP_NODE_API_CORE_URL + "/update-group",
    updateImages: process.env.REACT_APP_NODE_API_CORE_URL + "/update-images",
    createBook: process.env.REACT_APP_NODE_API_CORE_URL + "/create-book",
    membersRole: process.env.REACT_APP_NODE_API_CORE_URL + "/member-role",
    memberRequest: process.env.REACT_APP_NODE_API_CORE_URL + "/member-request",
    memberStatus: process.env.REACT_APP_NODE_API_CORE_URL + "/member-status",
    requestResponse: process.env.REACT_APP_NODE_API_CORE_URL + "/request-response",
    myRequests: process.env.REACT_APP_NODE_API_CORE_URL + "/my-requests",
    getGroupByCode: process.env.REACT_APP_NODE_API_CORE_URL + "/group-by-code",
    getGroupsByMemberCode: process.env.REACT_APP_NODE_API_CORE_URL + "/groups-by-membercode",
    booksByGroup: process.env.REACT_APP_NODE_API_CORE_URL + "/book-by-group",
    deleteBook: process.env.REACT_APP_NODE_API_CORE_URL + "/delete-books",
    booksByCategory: process.env.REACT_APP_NODE_API_CORE_URL + "/books-by-category",
    editBook: process.env.REACT_APP_NODE_API_CORE_URL + "/edit-book",
    uploadChapter: process.env.REACT_APP_NODE_API_CORE_URL + "/upload-chapter",
    getChapters:  process.env.REACT_APP_NODE_API_CORE_URL + "/get-chapters",
    getChaptersPreview:  process.env.REACT_APP_NODE_API_CORE_URL + "/get-chapters-preview",
    editChapter: process.env.REACT_APP_NODE_API_CORE_URL + "/edit-chapter",
    deleteChapter:  process.env.REACT_APP_NODE_API_CORE_URL + "/delete-chapter",
    createComment: process.env.REACT_APP_NODE_API_CORE_URL + "/create-comment",
    createBookComment: process.env.REACT_APP_NODE_API_CORE_URL + "/create-book-comment",
    getComments: (chapterId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/get-chapters-comments/${chapterId}`,
    getBookComments: (bookId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/get-book-comments/${bookId}`,
    createBookReply: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/create-book-reply/${commentId}`,
    createReply: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/create-reply/${commentId}`,
    deleteComment: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-comment/${commentId}`,
    deleteBookComment: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-book-comment/${commentId}`,
    deleteReply: (commentId, replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-reply/${commentId}/${replyId}`,
    deleteBookReply: (commentId, replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-book-reply/${commentId}/${replyId}`,
    addCommentLike: (commentId, replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/add-comment-like/${commentId}${replyId ? `/${replyId}` : ''}`,
    addBookCommentLike: (commentId, replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/add-book-comment-like/${commentId}${replyId ? `/${replyId}` : ''}`,
    editComment: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-comment/${commentId}`,
    editBookComment: (commentId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-book-comment/${commentId}`,
    editReply: (replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-reply/${replyId}`, // Agrega este endpoint para editar respuestas
    editBookReply: (replyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-book-reply/${replyId}`, // Agrega este endpoint para editar respuestas
    deleteNestedReply: (commentId, replyId, nestedReplyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-nested-reply/${commentId}/${replyId}/${nestedReplyId}`, // Endpoint para eliminar subrespuestas
    deleteNestedBookReply: (commentId, replyId, nestedReplyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/delete-nested-book-reply/${commentId}/${replyId}/${nestedReplyId}`, // Endpoint para eliminar subrespuestas
    editNestedReply: (commentId, replyId, nestedReplyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-nested-reply/${commentId}/${replyId}/${nestedReplyId}`, // Endpoint para editar subrespuestas
    editNestedBookReply: (commentId, replyId, nestedReplyId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/edit-nested-book-reply/${commentId}/${replyId}/${nestedReplyId}`, // Endpoint para editar subrespuestas
    createReport: process.env.REACT_APP_NODE_API_CORE_URL + "/create-report",
    userLibraryGet: (userId, bookCode) => `${process.env.REACT_APP_NODE_API_CORE_URL}/user-library/${userId}/${bookCode}`,
    userLibraryPost: `${process.env.REACT_APP_NODE_API_CORE_URL}/user-library`, 
    userLibraryPut: (userId, bookCode) => `${process.env.REACT_APP_NODE_API_CORE_URL}/user-library/${userId}/${bookCode}`,
    getBooksByStatus: (status, userId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/books-by-status/${status}/${userId}`,
    changeLibraryStatus: process.env.REACT_APP_NODE_API_CORE_URL + "/change-library-status",
    getUserHistory: (userId, bookId) => `${process.env.REACT_APP_NODE_API_CORE_URL}/user-history?userId=${userId}&bookId=${bookId}`,  // Endpoint GET con parámetros
    updateUserHistory: `${process.env.REACT_APP_NODE_API_CORE_URL}/user-history`,  
    createUserHistory: `${process.env.REACT_APP_NODE_API_CORE_URL}/user-history`,
    incrementBookViews: process.env.REACT_APP_NODE_API_CORE_URL + "/increment-book-views", 

};

export default Global;
