export default function NavBarPage() {
	return (
		<div className="top-banner">
			<div className="banner">
				<div className="item">
					<img className="img-banner" src="/img/banner/slider-trasparente.png" alt="" />
					<div className="container-slider">
						<div className="banner-anuncio">
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}