import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/index.css";
import "./assets/css/base.css";
import "./assets/css/style.css";
import Home from "./pages/Home";
import Us from "./pages/Us";
import Libros from "./pages/Libros";
import RecomendadosDelDia from "./pages/RecomendadosDelDia";
import BookDetail from "./pages/BookDetail";
import BooksReadChapter from "./pages/BooksReadChapter";
import Group from "./pages/Group";
import ComunidadLibroDetalle from "./pages/ComunidadLibroDetalle";
import CommunityRequest from "./pages/CommunityRequest";
import LibraryNoLogin from "./pages/LibraryNoLogin";
import Wallet from "./pages/Wallet";
import Profile from "./pages/Profile";
import ProfileLogin from "./pages/ProfileLogin";
import UploadBook from "./pages/UploadBook";
import BooksProfile from "./pages/BooksProfile";
import BookEdit from "./pages/BookEdit";
import BooksEditChapter from "./pages/BooksEditChapter";
import BookUploadChapter from "./pages/BookUploadChapter";
import ConfirmarCuenta from "./pages/ConfirmarCuenta";
import EditBookForm from "./pages/EditBookForm"
import NuevoPassword from "./pages/NuevoPassword";
import CreateGroup from "./pages/CreateGroup";
import {AuthProvider} from './context/AuthProvider'
import PrivateRoute from "./components/private-routes/PrivateRoute"


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <AuthProvider>
  <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/confirm/:token" element={<ConfirmarCuenta />} />
  <Route path="forget-password/:token" element={<NuevoPassword />} />
  <Route path="profile-login" element={<ProfileLogin />} />
  <Route path="quienes-somos" element={<Us />} />
  <Route path="libros" element={<Libros />} />
  <Route path="recomendados-del-dia" element={<RecomendadosDelDia />} />
  <Route path="/libros-detalle/:titulo" element={<BookDetail />} />
  <Route path="/libros_lectura_capitulo/:chapterName/:chapterNumber/:chapterCode/:bookCode" element={<BooksReadChapter />} />
  <Route path="/comunidad" element={<Group />} />
  <Route path="comunidad/:name" element={<ComunidadLibroDetalle />} />
  <Route path="/biblioteca" element={<LibraryNoLogin />} />
   
   {/*rutas privadas*/}
  <Route element={<PrivateRoute/>}>
  <Route path="/subir-libro" element={<UploadBook />} /> 
  <Route path="profile" element={<Profile />} />
  <Route path= "/mis-libros/:agrupacion/:code" element= {<BooksProfile />} />
  <Route path= "/libros-editar/:titulo" element= {<BookEdit/>} />
  <Route path= "/book-edit-form" element={<EditBookForm/>} />
  <Route path= "/libros-editar-capitulo" element={<BooksEditChapter /> } />
  <Route path= "/libros-subir-capitulo/:titulo" element= {<BookUploadChapter />  } />
  <Route path= "/comunidad-solicitudes" element= {<CommunityRequest /> } />
  <Route path= "/billetera" element={ <Wallet /> } />
  <Route path="/nueva-agrupacion"  element={<CreateGroup />} />
  </Route>
  </Routes>
    </AuthProvider>
  </BrowserRouter>
);
