import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './BuscadorMovil.css';

const BuscadorMovil = () => {
  return (
    <Dropdown className=' buscador-container hide_desk' style={{ width: '340px', height: '40px', borderRadius: '20px', }}>
      <Dropdown.Toggle variant="default" id="dropdown-buscador">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
           src="/img/svg/icon_search.svg"
            alt="Icono de búsqueda"
            width="20"
            height="20"
            style={{ marginRight: '10px' }} 
          />
          <span className='text-container'>Buscar Mangas y Novelas</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item header>Búsquedas recientes</Dropdown.Item>
        <Dropdown.Item>
          <div className='aspa' style={{ display: 'inline-block', marginLeft: '10px' }}>
            <img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" width="10" height="10" />
          </div>
          <span style={{ display: 'inline-block' }}>Sangre</span>
          
        </Dropdown.Item>
        <Dropdown.Item>
          <div className='aspa' style={{ display: 'inline-block', marginLeft: '10px' }}>
            <img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" width="10" height="10" />
          </div>
          <span style={{ display: 'inline-block' }}>Fukushuu o Koinegau yussha</span>
          
        </Dropdown.Item>
        <Dropdown.Item>
          <div className='aspa' style={{ display: 'inline-block', marginLeft: '10px' }}>
            <img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" width="10" height="10" />
          </div> 
          <span style={{ display: 'inline-block' }}>Bellezas en la isla Desierta</span>
        </Dropdown.Item>
        <Dropdown.Item>
          <div className='aspa' style={{ display: 'inline-block', marginLeft: '10px' }}>
            <img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" width="10" height="10" />
          </div>
          <span style={{ display: 'inline-block' }}>Hoinegau saikyou yussha</span>
          
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BuscadorMovil;

