import React from "react";
import useFormattedNumber from "../../hook/useFormattedNumber";

export default function SectionLeftDetailComunidad({detailGroup}) {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const protocolAndHost = protocol + "//" + host;
  return (
    <li className="col01 w-28 gris-F5F7FA oculta_movile">
      <div className="box Party">
        <img
          className="circulo"
          src={detailGroup && detailGroup?.images[0]?.image.path}
          alt=""
        />
        <div className="circulo-texto" style={{ width: "100px" }}>
          <h2 className="text-truncate">
            {detailGroup && detailGroup.groupName}
          </h2>
        </div>
      </div>
      <div className="box icons">
        <ul>
          <li>
            <img
              src={`${protocolAndHost}/img/interna/somos-party/like.png`}
              alt=""
            />
          </li>
          <li>
            <img
              src={`${protocolAndHost}/img/interna/somos-party/banderita.png`}
              alt=""
            />
          </li>
          <li>
            <img
              src={`${protocolAndHost}/img/interna/somos-party/p.jpg`}
              alt=""
            />
          </li>
          <li>
            <img
              src={`${protocolAndHost}/img/interna/somos-party/anteojos.jpg`}
              alt=""
            />
          </li>
          <li>
            <img
              src={`${protocolAndHost}/img/interna/somos-party/f.jpg`}
              alt=""
            />
          </li>
        </ul>
      </div>
      <div className="box estadistica">
        <ul>
          <li>
            <div className="item">
              {detailGroup?.groupRate}{" "}
              <img
                src={`${protocolAndHost}/img/interna/comunidad_libro_detalle/estrella_azul.png`}
                alt=""
              />
            </div>
            <div className="item">Puntuación</div>
          </li>
          <li>
            <div className="item">
              {useFormattedNumber(detailGroup?.groupFollowers)}
            </div>
            <div className="item">Seguidores</div>
          </li>
          <li>
            <div className="item">{detailGroup?.groupBooks}</div>
            <div className="item">Libros</div>
          </li>
          <li>
            <div className="item">1</div>
            <div className="item">Top</div>
          </li>
        </ul>
      </div>
      <div className="box informacion">
        <div className="titulo">Información</div>
        <p>
        {detailGroup && detailGroup?.groupDescription}
        </p>
      </div>
    </li>
  );
}
