import React from "react";
import toastr from 'toastr'; 
import 'toastr/build/toastr.min.css'; 
import EndpointCore from "../../pages/Global"; 

export default function CardCommunityRequest({ userName, memberType, createdAt, memberCode }) {
  const handleAccept = async () => {
    try {
      const response = await fetch(EndpointCore.requestResponse, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          response: 1 , 
          code: memberCode,
        })
      });
      if (!response.ok) {
        throw new Error("Error al aceptar la solicitud");
      }
      toastr.success('La solicitud ha sido aceptada correctamente');
    } catch (error) {
      console.error(error);
      toastr.error('Error al aceptar la solicitud');
    }
  };

  const handleReject = async () => {
    try {
      const response = await fetch(EndpointCore.requestResponse, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          response: 0, 
          code: memberCode,
        })
      });
      if (!response.ok) {
        throw new Error("Error al rechazar la solicitud");
      }
      toastr.success('La solicitud ha sido rechazada correctamente');
    } catch (error) {
      console.error(error);
      toastr.error('Error al rechazar la solicitud');
    }
  };

  return (
    <li className="irem">
      <div className="item avatar">
        <img src="img/interna/libro_detalle/icon_avatar.png" alt="" />
      </div>
      <div className="item text">
        <ul className="list-cargo">
          <li className="nombre">{userName}</li>
          <li className="cargo-nombre">
            <span className="cargo">{memberType}</span>
            <span className="tiempo">{createdAt}</span>
          </li>
        </ul>
      </div>
      <div className="item button" style={{ display: 'flex' }}>
        <button className="btn btn-rosado m-1" type="button" onClick={handleAccept}>
          Aceptar
        </button>
        <button className="btn btn-blanco" type="button" onClick={handleReject}>
          Rechazar
        </button>
      </div>
    </li>
  );
}

