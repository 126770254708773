import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";

const Dropdown = ({ options, onSelect, label, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Referencia al contenedor del dropdown

  // Función para manejar el clic fuera del dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Cierra el dropdown si el clic fue fuera de él
    }
  };

  // Añadir y limpiar el event listener en el useEffect
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedValue(option.item || option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState); // Alterna el estado de apertura del dropdown
  };

  const handleChevronClick = (event) => {
    event.stopPropagation(); // Evita que el clic en la flecha cierre el dropdown si ya está abierto
    toggleDropdown();
  };

  return (
    <div
      className="custom-dropdown-custom w-100"
      ref={dropdownRef} // Asigna la referencia al contenedor del dropdown
    >
      <div className="d-flex" onClick={toggleDropdown}>
        {/* Mostrar el label solo si no hay una opción seleccionada */}
        {!selectedValue && <span>{label}</span>}

        {/* Muestra el valor seleccionado en el centro cuando hay una opción */}
        {selectedValue && (
          <span className="selected-text" style={{ textAlign: "center" }}>
            {selectedValue}
          </span>
        )}

        <div className="dropdown-icon" onClick={handleChevronClick}>
          {!isOpen ? (
            <BiChevronDown color="#5F36DA" />
          ) : (
            <BiChevronUp color="#FF0055" />
          )}
        </div>
      </div>

      {isOpen && (
        <ul className="dropdown-menu-custom">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionSelect(option.item || option)}>
              {option.item || option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
