import React, { useState } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import './style.css';

function Filtro(props) {
    
    const data = props.data;
    const ordenarOptions = data[0].data;
    const mostrarOptions = [15, 20, 25, 30, 35];
    const [tituloDropdown, setTituloDropdown] = useState(data[0].recomendados);
    const [tituloMostrar, setTituloMostrar] = useState(mostrarOptions[0]);
    
    const handleOptionSelect = (titulo) => {
        setTituloDropdown(titulo);
    };
    
    const handleMostrarSelect = (titulo) => {
        setTituloMostrar(titulo);
    };
    return (
        <div className="filtro-tab">
            <ul className="d-flex align-items-center">
            
               <div className="  ui_cols--order_list">
                    <div className="ui_col">
                        <div className="ui_paragraph_12 ui_paragraph_12--medium">
                            <span>libros 1-280</span>
                        </div>
                    </div>
                    <div className=" resultados oculta_desktop">
                        <div className="ui_paragraph_12 ui_paragraph_12--medium">
                            <span>90 resultados</span>
                        </div>
                    </div>
                </div>
            
                <li><span>{data[0].nro_result}</span></li>
                <li className="mr-2">
                    <DropdownButton
                        as={ButtonGroup}
                        title={`Ordenar por: ${tituloDropdown}`}
                        id="dropdownID"
                        className='custom-dropdown-button'
                        
                    >
                         <Dropdown.Item className="custom-dropdown-title">
                            <span>ORDENADO POR</span>
                        </Dropdown.Item>
                        {ordenarOptions.map((option, index) => (
                            <Dropdown.Item
                                key={index}
                                className="custom-dropdown-item"
                                onClick={() => handleOptionSelect(option.ibutton)} // Cambiar el título al hacer clic
                            >
                                <div className="custom-dropdown-circle">
                                    <div className="custom-dropdown-check" />
                                </div>
                                <span>{option.ibutton}</span>
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </li>
                <li>
                <DropdownButton
                        as={ButtonGroup}
                        title={`Mostrar: ${tituloMostrar}`}
                        id="dropdownID"
                        className='custom-dropdown-button'
                    >
                        
                        <Dropdown.Item className="custom-dropdown-title">
                            <span>{tituloMostrar}</span>
                        </Dropdown.Item>
                        {mostrarOptions.map((option, index) => (
                            <Dropdown.Item
                                key={index}
                                className="custom-dropdown-item"
                                onClick={() => handleMostrarSelect(option)}
                            >
                                <div className="ui_dropdown_circle">
                                    <div className="ui_dropdown_circle__check" />
                                </div>
                                <span>{option}</span>
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </li>
                <li>
                    <div className="grid-filtro"><img src="/img/svg/icon_order_list_1.svg" alt="" /></div>
                    <div className="grid-filtro"><img src="/img/svg/icon_order_list_2.svg" alt="" /></div>
                </li>
            </ul>
        </div>
    );
}

export default Filtro;
