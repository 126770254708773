import React from "react";
import DropdownForm from "../dropdownForm/DropdownForm";


export default function index() {
  const dropdownOptions = ["Opción 1", "Opción 2", "Opción 3"];
  const handleOptionSelect = (option) => {
    console.log("Opción seleccionada:", option);
  };
  return (
    <div id="tab1" class="tabcontent" style={{ display: "block" }}>
      <div class="box ">
        <ul class="catalogo">
          <li class="ibillete">
            <span>Resumen</span>
            <div className="group-date historia">
              <span>Fecha de:</span>
              {/* <img class="ifoto" src="img/icalendar.png" alt="" /> */}
              <input className="" type="date" />
            </div>
            <div className="group-date historia">
              <span>Hasta:</span>
              {/* <img class="ifoto" src="img/icalendar.png" alt="" /> */}
              <input className="" type="date" />
            </div>
          
          </li>
          <li class="ibillete" style={{paddingBottom: 0}}>
            <span>Método de Pago</span>
            <div className="group-date no-borde" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
             <img src="img/paypal.png" style={{ marginRight: '10px' }} />
             <span >Paga con PayPal</span>
           </div>


          </li>
          <li class="ibillete" style={{paddingBottom: 0}}>
            <span>Correo Electrónico</span>
            <div class="group-date no-borde">
              <span>ejemplo@gmial.com</span>
            </div>
          </li>
          <li class="ibillete" style={{paddingBottom: 0}}>
            <span>Inicio de ciclo de pago</span>
            <div class="group-date no-borde">
              <span>01-02-2022</span>
            </div>
          </li>

          <div className="ibillete">
            <span>Historial de Transacción</span>

            <div className="group-date historia">
              <span>Periodo de Pago</span>
              <input className="input-pago" type="text" />
            </div>
            <div className="group-date historia">
              <DropdownForm
                options={dropdownOptions}
                onSelect={handleOptionSelect}
                label={"ID de Transacción"}
              />
            </div>
          </div>

          <div className="ibillete">
            <div className="group-date historia">
              <span>Estado de pago</span>
              <input className="input-pago" type="text" />
            </div>
            <div className="group-date historia">
              <DropdownForm
                options={dropdownOptions}
                onSelect={handleOptionSelect}
                label={"Fecha de pago"}
              />
            </div>
          </div>

          <div className="ibillete">
            <div className="group-date historia">
              <span>Dinero recaudado</span>
              <input className="input-pago" type="text" />
            </div>
          </div>
          <div className="ibillete">
            <div className="group-date historia" style={{border: "none"}}>
             
            </div>
          </div>
        </ul>
      </div>
      
    </div>
  );
}
