import React from "react";
import { parseISO, differenceInHours, differenceInMinutes, differenceInDays } from "date-fns";

export default function CardLibraryNoLogin({ book, isSelection, selectedBooks, handleCheckboxChange, isHistorial }) {

  const getCustomTimeAgo = (updatedAt) => {
    // Verificar si la fecha es válida
    if (!updatedAt) {
      return "Fecha no disponible";
    }

    try {
      const now = new Date();
      const parsedDate = parseISO(updatedAt);

      const daysDifference = differenceInDays(now, parsedDate);
      const hoursDifference = differenceInHours(now, parsedDate);
      const minutesDifference = differenceInMinutes(now, parsedDate);

      if (daysDifference > 0) {
        return `hace ${daysDifference} días`;
      } else if (hoursDifference > 0) {
        return `hace ${hoursDifference} horas`;
      } else {
        return `hace ${minutesDifference} minutos`;
      }
    } catch (error) {
      console.error("Error al procesar la fecha:", error);
      return "Fecha inválida";
    }
  };

  return (
    <li className={isHistorial ? "card-history" : "ibanner"}>
      <div className="item">
        {isSelection && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={book.id}
              id={`book-${book.id}`}
              onChange={(e) => handleCheckboxChange(e, book.id)}
              checked={selectedBooks.includes(book.id)}
            />
          </div>
        )}
        <img src={book.profileImage} alt="Imagen del libro" />
      </div>
      
        {!isHistorial ? (
          <div>
          <div className="item text">{book.title}</div>
          <div className="item estrellita">
            {book.lastVisitedChapter === "No leído" ? (
            <div className="no-leido">
              Hasta Cap. {book.totalChapters}
            </div>
            ) : (
            <>
            <div className="num-rosado">{book.remainingChapters}</div>
            <div className="cap">Cap.{book.lastVisitedChapter}</div>
            </>
             )}
          </div>        
       </div>

        ) : (
          <div className="history-info">
            <div className="history-title">
              {book.title}
            </div>
            <div className="cap">
              Cap.{book.chapter_number}
            </div>
            <div className="date">
              <p>
                {getCustomTimeAgo(book.last_visited_at)}
              </p>
            </div>
            <div className="group-author">
              {book.author}
              </div>
            </div>
        )}
    </li>
  );
}
