import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./style.css";

export default function ContenidoOriginalModal({ show, setShow, onSeleccionarOpcion }) {
  const handleClose = () => setShow(false);

  const [seleccionada, setSeleccionada] = useState(null);

  const opcionesLicencias = [
    { id: 1, titulo: "Todos los derechos reservados", descripcion: "No permites que tu trabajo se utilizado o adaptado, bajo ningún concepto, sin tu permiso." },
    { id: 2, titulo: "Dominio Público", descripcion: "Esto permite que cualquier persona use tu historia para cualquier propósito - cualquiera puede, de forma gratuita, imprimir, vender o convertir en película tu trabajo." },
    { id: 3, titulo: "Atribución de Creative Commons (CC)", descripcion: "Mantienes algunos derechos sobre tu historia pero concedes ciertos permisos como traducciones o adaptaciones, siempre y cuando recibas el crédito correspondiente." },
    { id: 4, titulo: "Atribución de (CC) No Comercial", descripcion: "Esta licencia permite que otros remezclen, adapten y creen sobre tu trabajo, sin fines comerciales, siempre y cuando te den el crédito correspondiente." },
    { id: 5, titulo: "Atribución de (CC) no Com. Sin Derivados", descripcion: "Esta licencia permite a otros compartir tu trabajo sin alteraciones, siempre y cuando te den el crédito por el mismo." },
    { id: 6, titulo: "Atribución de (CC) no Com. ShareAlike", descripcion: "Esto permite que otros se adapten y desarrollen tu trabajo, incluso con fines comerciales, siempre y cuando te den crédito por el mismo y las mismas condiciones sean aplicadas." },
    { id: 7, titulo: "Atribución de (CC). Sin derivados", descripcion: "Esto permite a otros reutilizar tu trabajo para cualquier propósito, sin embargo no puede ser alterado y deben darte crédito por el mismo." },
  ];

  const manejarSeleccion = (id) => {
    const opcionSeleccionada = opcionesLicencias.find(opcion => opcion.id === id);
    setSeleccionada(opcionSeleccionada);
    onSeleccionarOpcion(opcionSeleccionada); // Pasamos la opción seleccionada al componente padre
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} className="contenido-original-modal">
      <Modal.Header className="custom-modal-header">
        <button className="custom-close-button" onClick={handleClose}>
          {/* Icono de cierre */}
        </button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-section first" style={{display:"block", textAlign: "center", alignItems:"center"}}>
            <h5 className="contenido-original">Contenido Original</h5>
            <h6>Seleccionar la opción</h6>        
          </div>
          {opcionesLicencias.map((opcion) => (
            <div key={opcion.id} className="modal-section">
              <div>
              <input 
                type="checkbox" 
                className="checkbox-input" 
                checked={seleccionada?.id === opcion.id}
                onChange={() => manejarSeleccion(opcion.id)}
              />
              </div>
              <div>
                <h5>{opcion.titulo}</h5>
                <p>{opcion.descripcion}</p>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
