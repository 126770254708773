import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";
import ModaComponent from "../ModalComponent";

const DropdownAttribute = ({
  options,
  onSelect,
  label,
  initialValue,
  modalTitle,
  modalSubtitle,
  singleSelect = false,
  maxLimit,
}) => {
  const [selectedValues, setSelectedValues] = useState(
    initialValue ? (singleSelect ? [initialValue] : initialValue) : []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const dropdownRef = useRef(null); // Usamos un ref para el contenedor principal

  const handleOptionSelect = (option) => {
    const selectedValue = option.value || option.item;
    let newSelectedValues;

    if (singleSelect) {
      newSelectedValues = [selectedValue];
    } else {
      if (selectedValues.includes(selectedValue)) {
        newSelectedValues = selectedValues.filter(
          (value) => value !== selectedValue
        );
      } else {
        if (selectedValues.length >= maxLimit) {
          alert(`Puedes elegir hasta ${maxLimit} opciones para ${label}`);
          return;
        }
        newSelectedValues = [...selectedValues, selectedValue];
      }
    }

    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
  };

  const handleSaveSelection = () => {
    console.log("Selección guardada:", selectedValues);
    setIsModalOpen(false);
  };

  const handleClickOutside = (event) => {
    // Verificamos si el clic fue fuera del modal o del dropdown
    if (
      modalRef.current && !modalRef.current.contains(event.target) &&
      dropdownRef.current && !dropdownRef.current.contains(event.target)
    ) {
      // Si el clic es fuera del modal, restablecer las opciones al valor inicial
      setSelectedValues(initialValue ? (singleSelect ? [initialValue] : initialValue) : []);
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    // Restablecer las opciones seleccionadas al valor inicial cuando se cierra el modal
    setSelectedValues(initialValue ? (singleSelect ? [initialValue] : initialValue) : []);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="custom-dropdown w-100" ref={dropdownRef}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setIsModalOpen(true)}
      >
        {/* Mostrar el label solo si no hay opciones seleccionadas */}
        {!selectedValues.length && <span>{label}</span>}

        {/* Mostrar el texto basado en las opciones seleccionadas */}
        <span className="selected-options-text" style={{ textAlign: 'center' }}>
         {selectedValues.length > 0 && (
           singleSelect
            ? options.find(option => (option.value || option.item) === selectedValues[0])?.label || selectedValues[0]
            : label === "Demografía"
            ? selectedValues.join(", ") // Para demografía, mostrar los valores seleccionados como texto
            : `${label}: ${selectedValues.length} ${selectedValues.length === 1 ? 'Selección' : 'Selecciones'}` // Singular o plural según la cantidad de selecciones
          )}
        </span>
        <div className="dropdown-icon">
          {!isModalOpen ? (
            <BiChevronDown color="#5F36DA" />
          ) : (
            <BiChevronUp color="#FF0055" />
          )}
        </div>
      </div>

      <ModaComponent show={isModalOpen} setShow={setIsModalOpen} ref={modalRef}>
        <div className="modal-header text-center">
          <h3>{modalTitle}</h3>
          <h6>{modalSubtitle}</h6>
          <button
            className="custom-close-button"
            onClick={handleCloseModal} // Cerrar el modal y restablecer la selección
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ffffff"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l4.646-4.647a.5.5 0 1 1 .708.708L8.707 8l4.647 4.646a.5.5 0 1 1-.708.708L8 8.707l-4.646 4.647a.5.5 0 0 1-.708-.708L7.293 8 2.646 3.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </div>
        <div className="dropdown-options-container">
          {options.map((option, index) => (
            <div key={index} className="dropdown-option">
              <input
                type="checkbox"
                className="styled-checkbox"
                checked={selectedValues.includes(option.value || option.item)}
                onChange={() => handleOptionSelect(option)}
              />
              <label>{option.label || option.item}</label>
            </div>
          ))}
        </div>

        <div className="modal-footer">
          <button className="btn btn_rosado" onClick={handleSaveSelection}>
            Guardar selección
          </button>
        </div>
      </ModaComponent>
    </div>
  );
};

export default DropdownAttribute;
