import React from "react";

export default function Card({ book, handleBookClick }) {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const protocolAndHost = `${protocol}//${host}`;

  return (
    <li className="ibanner">
      <div
        className="item"
        onClick={() => handleBookClick(book.bookTitle)}
        style={{ cursor: 'pointer' }}
      >
        <img src={book.images[0].image.path} alt="" />
      </div>
      <div className="item text">{book.bookTitle}</div>
      <div className="item estrellita">
        <img
          src={`${protocolAndHost}/img/interna/tab01/estrella_azul.png`}
          alt=""
        />
        <img
          src={`${protocolAndHost}/img/interna/tab01/estrella_azul.png`}
          alt=""
        />
        <img
          src={`${protocolAndHost}/img/interna/tab01/estrella_azul.png`}
          alt=""
        />
        <img
          src={`${protocolAndHost}/img/interna/tab01/estrella_azul.png`}
          alt=""
        />
        <img
          src={`${protocolAndHost}/img/interna/tab01/estrella_blanca.png`}
          alt=""
        />
        <span>{book.bookRate}</span>
      </div>
    </li>
  );
}