import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Alerta from '../components/alerta/Alerta';
import Footer from '../components/footer/Footer';
import EndpointCore from './Global';

const NuevoPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [tokenValido, setTokenValido] = useState(false);
  const [passwordModifided, setPasswordModifided] = useState(false);
  const params = useParams();
  const { token } = params;

  useEffect(() => {
    const comprobarToken = async () => {
      try {
        const url = `${EndpointCore.forgetPassword}/${token}`;
        await fetch(url, { method: 'GET' });
        setTokenValido(true);
      } catch (error) {
        toastr.error(error.message || 'Error al verificar el token');
      }
    };

    comprobarToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword.length < 6) {
      toastr.error('El Password debe ser mínimo de 6 caracteres');
      return;
    }

    if (newPassword !== repeatPassword) {
      toastr.error('Las contraseñas no coinciden');
      return;
    }

    try {
      const url = `${EndpointCore.forgetPassword}/${token}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        toastr.success(data.msg);
        setPasswordModifided(true);
      } else {
        const errorData = await response.json();
        toastr.error(errorData.msg);
      }
    } catch (error) {
      toastr.error(error.message || 'Error al modificar la contraseña');
    }
  };


  return (
    <>
      <h1 className="text-capitalize">
        Restablece tu Password
      </h1>

      <form onSubmit={handleSubmit} className="form-container">

        <div>
          <label className="label-password" htmlFor="password">
            Nuevo Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="Ingresa tu nuevo password"
            className="input-confirm"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label className="label-password" htmlFor="repeatPassword">
            Repetir Password
          </label>
          <input
            id="repeatPassword"
            type="password"
            placeholder=" Repite tu nuevo password"
            className="input-confirm "
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
          <input
            type="submit"
            value="Restablecer Password"
            className="input-password-submit"
          />
        </div>
      </form>

      {passwordModifided && (
        <div>
          <Link
            className='input-confirm'
            to="/"
          >
            Inicia Sesión
          </Link>
        </div>
      )}

      <Footer />
    </>
  );
};

export default NuevoPassword;
