import './carrusel.css';
import React from "react";
import Carruselx from "react-slick";

function Carrusele(props){
    const icarrusel = props.icarrusel;    
    const titulo = props.titulo;    
    const key_position = props.key_position; 
    const config = props.config; 
     
    var settings = {
      slidesToShow: config.slidesToShow,
      slidesToScroll: config.slidesToScroll,
      centerMode: false,
      centerPadding: "0",
      arrows: false,
      rtl: false,
      draggable: config.draggable,
      variableWidth: (parseInt(config.rows) > 1)? false : true,
      rows: config.rows,  
      responsive: [
          {
              breakpoint: 2500,
              settings: {
                  slidesToShow: config.responsive.slidesToShow,
                  slidesToScroll: config.responsive.slidesToScroll,
              },
          },
          {
              breakpoint: 1800,
              settings: {
                  slidesToShow: config.responsive.slidesToShow,
                  slidesToScroll: config.responsive.slidesToScroll,
              },
          },
          {
              breakpoint: 992,
              settings: {
                  slidesToShow: config.responsive.slidesToShow,
                  slidesToScroll: config.responsive.slidesToScroll,
              },
          },
          {
              breakpoint: 776,
              settings: {
                  slidesToShow: config.responsive.slidesToShow,
                  slidesToScroll: config.responsive.slidesToScroll,
                  rows: config.rows, // This doesn't appear to work in responsive (Mac/Chrome)
              },
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: config.slidesToShow,
                  //centerMode: true,
                  centerPadding: "20px",
                  rows: config.rows, // This doesn't appear to work in responsive (Mac/Chrome)
              },
          },
      ],
    };
    return(
      
      <div className='carrusel'> 
       <div className="icard-header">
        <div className="icar-h4"><div className='mi-titulo'>{titulo}</div></div>
        <div className="icar-boton"> 
           <a href="/#"><img className='link-simple normal hide_desk' src="/img/svg/icon_arrow_right_5028C6.svg" alt="" /></a>
           <a href="/#"><img className='link-simple white hide_desk' src="/img/svg/icon_arrow_right_FFFFFF.svg" alt="" /></a>
          <button type="button hide_movil">Mostrar más</button></div>
       </div>       
        <div className="ui_carrusel">
        <Carruselx >       
          {
            icarrusel.map((v, i) =>            
              <div key={key_position+i} className="icar item cd">
              <div className={`pic-nuevos-capitulos`}><span> Nuevos capitulos</span></div> 
              <div className="info-foto">
                <ul>
                  <li className='picture'>
                    <div className='content-foto'>
                    <div className={`form-check`}>
                      <input className="form-check-input" type="checkbox" defaultValue id={key_position+i+'type1'} />
                    </div>
                      <img className={`pic-estrellita`} loading="lazy" src="/img/svg/icon_poster_star.svg" alt="" />
                      <img className='pic-foto'  src={v.foto} alt="" />
                      <div className={`pic-categ`}>{v.novela}</div>
                      <div className={`pic-titulo`}> {v.titulo}</div>
                    </div>
                  </li>
                  <li  className={`form-check`}>textotexto</li>
                  <li>
                    <div className={`nro`}>21</div>
                    <div  className={`subtitulo`}>Subcapit</div>
                  </li>
                </ul>
              </div>
              <div className="info-descripcion">
                  <ul>
                    <li className="boton puntuacion">  
                      <div className='number'>
                      <img className={`estrellita`} loading="lazy" src="/img/svg/icon_star_purple.svg" alt="" />
                      <img className={`estrellita_white`} loading="lazy" src="/img/svg/icon_star_white.svg" alt="" />
                      <span>8.9 (53)</span>
                      </div>
                    
                    <div className="red nuevo">Nuevo</div><div className="red">{v.manga.substring(0, 6)}</div><div className="blue">{v.shoujo.substring(0, 6)}</div></li>
                    <li className="titulo">{v.texto}</li>
                    <li className="subtitulo">{v.capitulo.substring(0, 7)}</li>
                    <li className="descripcion">{v.autor}</li>
                    <li className="puntuacion">
                      <div className='number'><span>{v.puntuacion} </span>
                      <img className={`estrellita`} loading="lazy" src="/img/svg/icon_star_purple.svg" alt="" />
                      <img className={`estrellita_white`} loading="lazy" src="/img/svg/icon_star_white.svg" alt="" />
                      </div><span>Puntuación</span></li>
                    <li className="vistas nro_cap"><span>20 Capítulos</span></li>
                    <li className="vistas"><div className='number'><span>{v.vistas}</span></div><span>Vistas</span></li>
                    <li className="time">{v.time}</li>
                  </ul>
              </div>
              <div className="linea_completa"></div>
              <div className="linea_completa2">
                <div className="linea_media_left" />
                <div className="linea_media_right" />
              </div>

              </div> 
                    
          )}
           </Carruselx>         
          </div>  
        </div>  
       
    );
  
}
export default Carrusele;