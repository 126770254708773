import React from 'react';
function Buscar(){
        return(
            <div className='html'>
                <ul>
                    <li><input type="text" placeholder="Buscar mangas o novelas" className="input-search" name="search" /></li>
                    <li>
                        <ul className='info-login'>
                            <li><span>Búsquedas recientes</span></li>
                            <li>
                              <span>Sangre</span><div className='aspa'><img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" /></div>
                            </li>
                            <li>
                              <span>Fukushuu o Koinegau yussha</span><div className='aspa'><img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" /></div>
                            </li>
                            <li>
                              <span>Bellezas en la isla Desierta</span><div className='aspa'><img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" /></div>
                            </li>
                            <li>
                              <span>Hoinegau saikyou yussha</span><div className='aspa'><img loading="lazy" src="/img/svg/aspa_delete.svg" alt="" /></div>
                            </li>                          
                        </ul>
                    </li>
                </ul>
            </div>
        );     
}
export default Buscar;