import React, { useState } from "react";
import TabsMain from "../tabs-main";
import Card from "../cardMainDetailComunidad";

export default function SectionMainDetailComunidad({ detailGroup, handleBookClick }) {
  const [tab, setTab] = useState({
    id: 1,
    name: "Todos",
  });
  const optionTabs = [
    {
      id: 1,
      name: "Todos",
    },
    {
      id: 2,
      name: "Seguidos",
    },
    {
      id: 3,
      name: "Guardados",
    },
    {
      id: 4,
      name: "Abandonado",
    },
  ];
  const protocol = window.location.protocol;
  const host = window.location.host;
  const protocolAndHost = protocol + "//" + host;
  return (
    <li id="zona-blue" className="col01 w-71 div">
      <div className="lbox_container">
        <div className="estadistica oculta_desktop">
          <ul>
            <li>
              <div className="item">
                8.9{" "}
                <img
                  src={`${protocolAndHost}/img/interna/comunidad_libro_detalle/estrella_azul.png`}
                  alt=""
                />
              </div>
              <div className="item">Puntuación</div>
            </li>
            <li>
              <div className="item">8k</div>
              <div className="item">Seguidores</div>
            </li>
            <li>
              <div className="item">235</div>
              <div className="item">Libros</div>
            </li>
            <li>
              <div className="item">1</div>
              <div className="item">Top</div>
            </li>
          </ul>
        </div>
        <div className="box texto-descriptivo oculta_desktop">
          <p>
            Qin Tian fue llevado a una isla desierta debido a un naufragio. Todo
            lo que tiene equipado es un sistema de supervivencia y una navaja.
            Tiene que empezar de cero. Construyendo un campamento, haciendo
            fuego y pescando, tiene que hacer todo lo posible para sobrevivir.
            ¡Afortunadamente, hay muchas bellezas que lo acompañan! Diosa de la
            escuela, hermana rubia, loli violenta...
          </p>
        </div>
        <div className="box red oculta_desktop">
          <span>
            Ver más{" "}
            <img
              src={`${protocolAndHost}/img/interna/comunidad_libro_detalle/flecha-red.png`}
              alt=""
            />
          </span>
        </div>
        <div className="box nuestro-libros">
          <h5>
          Nuestros Libros  {" "}
            <img
              className="oculta_desktop"
              src={`${protocolAndHost}/img/interna/comunidad_libro_detalle/flecha-azul.png`}
              alt=""
            />
          </h5>
        </div>
      </div>
      <div className="box grid-tab">
        <TabsMain optionTabs={optionTabs} setTab={setTab} tab={tab} />

        <ul className="ui_blocks tab-head oculta_movile">
          <li className="ui_blocks__li">
            <div className="ui_cols ui_cols--order_list">
              <div className="ui_col">
                <div className="ui_paragraph_12 ui_paragraph_12--medium">
                  <span>Libros 1-15 de280</span>
                </div>
              </div>

              <div className="ui_col">
                <div className="ui_order_list">
                  <div className="ui_order_list--dropdown dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenu2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ordenar por:
                      <span id="dropdown_id">Recomendados</span>
                    </button>
                    <ul
                      className="dropdown-menu cbo_libro"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <span className="libro_titulo">ORDENADO POR</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>Últimas actualizaciones</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>Recomendados</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>Más populares</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>Mejor valorados</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="ui_order_list--dropdown dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenu2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Mostrar:
                      <span id="dropdown_id">15</span>
                    </button>
                    <ul
                      className="dropdown-menu cbo_libro"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>20</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>25</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>30</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item--ui"
                          type="button"
                        >
                          <div className="ui_dropdown_circle">
                            <div className="ui_dropdown_circle__check"></div>
                          </div>
                          <span>35</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <ul className="ui_order_list_button tabs">
                    <li className="ui_order_list_button__item active">
                      <figure>
                        <img
                          src={`${protocolAndHost}/img/svg/icon_order_list_1.svg`}
                          alt=""
                        />
                      </figure>
                    </li>
                    <li className="ui_order_list_button__item">
                      <figure>
                        <img src="img/svg/icon_order_list_2.svg" alt="" />
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div id="tab1" className="tabcontent" style={{ display: "block" }}>
          <div className="box ">
            <ul className="catalogo">
              {detailGroup && detailGroup.books.length > 0 ? (
                detailGroup.books.map((book) => <Card key={book.bookId} book={book} handleBookClick={handleBookClick}/>)
              ) : (
                <h2 style={{height: 50}}>Aún no hay libros publicados</h2>
              )}
            </ul>
          </div>
        </div>
      </div>
      {detailGroup && detailGroup.books.length > 0 && (
        <div className="box more">
          <button>Mostrar más</button>
        </div>
      )}
      <div className="box miembros">
        <div className="blue-circle"></div>
        <div className="titulo">Miembros</div>
        <div className="avatares">
          <ul className="list-avatares">
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/1.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/2.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/3.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/4.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/5.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/6.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/7.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
            <li className="item">
              <div className="avatar">
                <img
                  src={`${protocolAndHost}/img/interna/miembros/8.png`}
                  alt=""
                />
              </div>
              <div className="avatar-text">
                <ul>
                  <li className="nombre">MarrioHG</li>
                  <li className="cargo">Lider</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="botones">
          <button className="unirse" type="button">
            <img
              src={`${protocolAndHost}/img/interna/miembros/user_rose.png`}
              alt=""
            />{" "}
            Unirse
          </button>
          <button className="more" type="button">
            Mostrar más
          </button>
        </div>
      </div>
    </li>
  );
}
