import '../assets/css/App.css';
import '../assets/css/interna.css';
import '../assets/css/movil.css';
import {React, useState} from "react";
import Menu from '../components/menu/Menu';
import Tabs from '../components/tab/Tabs';
import Dropdown from '../components/dropdown/Dropdown';
import Footer from '../components/footer/Footer';
import FooterC from '../components/footer/html/FooterC';
import Banner from '../components/banner/Banner';
import ModalFiltro from '../components/modal-filtro-libros/ModalFiltro';

function Libros() {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const protocolAndHost = protocol + "//" + host;
    const items_banner = [{ foto_movil: '/img/banner/bg_banner_home_libros.jpg', foto: '/img/banner/bg_banner_home_libros.jpg', texto: `Nuestros   Libros` }];
    const ibanner = [{ foto_movil: '/img/banner/slider-trasparente.png', foto: '/img/banner/slider-trasparente.png', texto: `` }];
  
    const itab = [
        { selected: 'true', href: 'comedia', texto: 'Comedia' },
        { selected: 'false', href: 'drama', texto: 'Drama' },
        { selected: 'false', href: 'fantasia', texto: 'Fantasía' },
        { selected: 'false', href: 'accion', texto: 'Acción' },
        { selected: 'false', href: 'drama2', texto: 'Drama' },
        { selected: 'false', href: 'fantasia2', texto: 'Fantasía' },
        { selected: 'false', href: 'accion2', texto: 'Acción' }]

    const icbo = [
        { cbo: 'Tipo', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { itemnpm : 'One shot' }] },
        { cbo: 'Etiquetas', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
        { cbo: 'Demografía', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] },
        { cbo: 'Por estado', cbo_items: [{ item: 'Manga' }, { item: 'Novela' }, { item: 'Manhwa' }, { item: 'Manhua2' }, { item: 'Doujinshi' }, { item: 'One shot' }] }
    ];
    var ancho = window.innerWidth;
    var footerComponent = ancho <= 900 ? <FooterC /> : <Footer />;
    // CONFIG CARRUSEL GENERO LIBROS
    var configGeneroLibros= { rows: 1, draggable: true, slidesToShow: 4,  };
    if (ancho <= 900) {
        // CONFIG CARRUSEL GENERO LIBROS
        configGeneroLibros = { rows: 9, draggable: true, slidesToShow: 1,  };
    } else {
        // CONFIG CARRUSEL GENERO LIBROS
        configGeneroLibros = { rows: 1, draggable: true, slidesToShow: 4,  };
    }
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const isUserLoggedIn = localStorage.getItem("token");

    return (
        <div className="interna libros ">
            <div className=' ui_container--946 ui_container '>
            <Menu></Menu>
            </div>
            <div className='position_top_A position'>
            
                <div className='ui_container ui_container--946'>
                

                    <div className='banner-simple '><Banner items_banner={items_banner}></Banner></div>
                </div>               
                <div className='ui_container ui_container--946'>
                    <div className="psidebar">
                        <div className='box-row subtitulo margin-button-20'>
                            <span>Filtrar Resultados </span>
                        </div>
                        <div className='box-row dropdown-blue'>{<Dropdown icbo={icbo} ></Dropdown>}</div>
                    </div>
                   <div className='pcontent'>
                    <div className='libros ' > 
                    <div className=' carrusel  '>            
                     <Tabs itab={itab} key_position={'tabkey002'} titulo={'Género'}  config={configGeneroLibros} showBanner={true}/> 
                   </div>                   
                   </div> 
                        <div className='box-row group-button limit'>
                            <button className="bg-rosado left">Anterior</button>
                            <button className="bg-rosado right">Siguiente</button>                  
                        </div>
                    </div>                   
                </div>
            </div>
               <button className='bg-filtrar oculta_desktop' onClick={handleOpenModal}> Filtrar </button> 
              
               {showModal && ( <ModalFiltro show={showModal} onHide={handleCloseModal} icbo={icbo} /> )}
              {footerComponent}
              </div>                 
    );
}

export default Libros;
