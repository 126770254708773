import { useState, useEffect, createContext } from 'react'
import EndpointCore from '../pages/Global'
import CustomImageLoader from '../components/CustomLoader/CustomImageLoader';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const autenticarUsuario = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false); 
        return;
      }

      const config = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await fetch(EndpointCore.profile, config);

        if (!response.ok) {
          throw new Error('Error al obtener el perfil del usuario');
        }

        const data = await response.json();

        setAuth(data);
      } catch (error) {
        console.error('Error en la autenticación:', error);
        setAuth({});
      } finally {
        setLoading(false); 
      }
    };

    autenticarUsuario();
  }, []);

  const cerrarSesionAuth = () => {
    setAuth({});
  };

  if (loading) {
    return <CustomImageLoader/>;
  }

    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuth,
                cerrarSesionAuth
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
export { 
    AuthProvider
}

export default AuthContext;